import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { use100vh } from 'react-div-100vh'

import NewsButton from "../components/news_button";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import Gid from "../components/gid";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Contacts from "../components/contacts";
import ReviewPopup from "../components/reviewPopup";
import Objects from "../components/objects";

// Patterns
import PatternFirst from "../images/pattern_01.png";
import PatternSecond from "../images/pattern_02.png";
import PatternThird from "../images/pattern_03.png";
import Arrow from "../images/icons/arrow_forward.svg";
import ArrowDown from "../images/icons/arrow_down_small.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { Thumbs, FreeMode, EffectFade, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Thumbs, FreeMode, EffectFade, Navigation]);

export default function Home({
  data: {
    page,
    posts,
    cottages,
    infrastucture,
    life,
    gmap,
    contacts,
    forms,
    header,
    footer,
    socials,
    copyright,
    user,
    cookies
  },
}) {
  const wpContent = page.nodes[0];
  const content = wpContent.pageHome;
  const cottage = cottages.nodes[0].blockCotteges;
  const is = infrastucture.nodes[0].blockInfrastuctureObjects;

  const height = use100vh()

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const postItems = posts.edges.slice(0, 3);

  forms = forms.nodes[0]
  gmap = gmap.nodes[0]
  contacts = contacts.nodes[0]

  return (
    <div className="homeLayout">
      <Layout
        footer={footer.nodes}
        header={header.nodes}
        forms={forms}
        user={user.nodes}
        cookies={cookies.nodes}
        copyright={copyright.nodes}
        socials={socials.nodes[0].socialNetworks}
        locale={page.nodes[0].locale.locale}
        uri={page.nodes[0].uri}
      >
        <SEO title={wpContent.seo.title} description={wpContent.seo.metaDesc} />
        <div style={{ height: height }} className="home__image">
          <GatsbyImage
            image={
              content.mainBannerImg.localFile.childImageSharp.gatsbyImageData
            }
            alt={content.mainBannerImg.localFile.childImageSharp.altText || ``}
          />
          <Link to="#about" className="toAbout">
            <div className="ico">
              <ArrowDown />
            </div>
            {content.mainBannerTitle}
          </Link>
          <div className="pattern">
            <img src={PatternFirst} alt="Pattern" />
          </div>
        </div>
        <div id="about" className="home__about">
          <div>
            <h1>{content.sectionAboutContent.title}</h1>
            <p>{content.sectionAboutContent.description}</p>
            <Link
              to={content.sectionAboutContent.buttonLink}
              className="button minWidth"
            >
              {content.sectionAboutContent.buttonText}
            </Link>
          </div>
          <div className="aboutIcons">
            {content.sectionAboutAdvantages.map((value, i) => (
              <div key={i} className="aboutIcons-item">
                <div
                  className="icon"
                  dangerouslySetInnerHTML={{
                    __html: value.icon,
                  }}
                ></div>
                {value.text}
              </div>
            ))}
          </div>
        </div>
        <div className="raundSlider">
          <Swiper
            className="thumbs"
            direction={"horizontal"}
            loop={true}
            speed={300}
            slidesPerView={1}
            spaceBetween={3}
            // centeredSlides={true}
            loopAdditionalSlides={4}
            slideToClickedSlide={true}
            onSwiper={setThumbsSwiper}
            freeMode={true}
            allowTouchMove={false}
            watchSlidesProgress={true}
            breakpoints={{
              "1300": {
                "spaceBetween": 10,
                "direction": "vertical"
              },
              "768": {
                "spaceBetween": 4,
                "direction": "vertical"
              }
            }}
          >
            {content.sectionGallery.map((value, i) => (
              <SwiperSlide key={i}>
                <GatsbyImage
                  image={value.localFile.childImageSharp.gatsbyImageData}
                  alt={value.altText || ``}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            // ref={ref}
            thumbs={{ swiper: thumbsSwiper }}
            loop={true}
            speed={500}
            effect={"fade"}
          >
            {content.sectionGallery.map((value, i) => (
              <SwiperSlide key={i}>
                <GatsbyImage
                  image={value.localFile.childImageSharp.gatsbyImageData}
                  alt={value.altText || ``}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Gid content={life.nodes[0].blockLifeAdvantages} />
        <div className="structure__block">
          <div className="mainImage structure">
            <GatsbyImage
              image={
                is.infrasructureObjectsImg.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt={is.infrasructureObjectsImg.altText || ``}
            />
            {is.infrasructureObjects.map((value, i) => (
              <Objects value={value} index={i} />
            ))}
          </div>
          <div className="structure__info">
            <h2>{content.sectionInfrastructureTitle}</h2>
            <div className="structure__icons">
              {content.sectionInfrastructureObjects.map(({ node }, i) => (
                <div key={i}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.sectionInfrastructureObjects[i].icon,
                    }}
                  ></div>
                  <span>{content.sectionInfrastructureObjects[i].name}</span>
                </div>
              ))}
            </div>
            <Link
              to={content.sectionInfrastructureButtonMoreLink}
              className="button minWidth"
            >
              {content.sectionInfrastructureButtonMoreText}
            </Link>
          </div>
        </div>
        <div className="patternBox reverseStyle">
          <div className="pattern">
            <img src={PatternSecond} alt="Pattern" />
          </div>
          <h2 className="alignCenter">{content.sectionServiceTitle}</h2>
          <ul className="iconsLine">
            {content.sectionServiceItemList.map(({ node }, i) => (
              <li key={i} className="iconsLine-item">
                <div
                  className="iconsLine-icon"
                  dangerouslySetInnerHTML={{
                    __html: content.sectionServiceItemList[i].icon,
                  }}
                ></div>
                <div className="iconsLine-desc">
                  {content.sectionServiceItemList[i].name}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <Tabs>
          <header className="tabsHead">
            <strong>{cottage.textBeforeToggler}</strong>
            <TabList>
              <Tab>{cottage.modernToggleText}</Tab>
              <Tab>{cottage.classicToggleText}</Tab>
            </TabList>
          </header>
          <TabPanel>
            <div className="cottages">
              {cottage.cottegesList.map((value, i) => (
                <div key={i} className="cottage__item">
                  <div>
                    <h2>{value.name}</h2>
                    <div className="cottage__details">
                      <div>
                        <div
                          className="ico"
                          dangerouslySetInnerHTML={{
                            __html: cottage.cottegeTotalAreaIcon,
                          }}
                        ></div>
                        <span>{cottage.cottegeTotalArea}</span>
                        <b>{value.cottegeParamsGroup.totalArea}</b>
                      </div>
                      <div>
                        <div
                          className="ico"
                          dangerouslySetInnerHTML={{
                            __html: cottage.cottegeLevelsIcon,
                          }}
                        ></div>
                        <span>{cottage.cottegeLevels}</span>
                        <b>{value.cottegeParamsGroup.floorLevels}</b>
                      </div>
                      <div>
                        <div
                          className="ico"
                          dangerouslySetInnerHTML={{
                            __html: cottage.cottegeTotalCarsIcon,
                          }}
                        ></div>
                        <span>{cottage.cottegeTotalCars}</span>
                        <b>{value.cottegeParamsGroup.cars}</b>
                      </div>
                      <div>
                        <div
                          className="ico"
                          dangerouslySetInnerHTML={{
                            __html: cottage.cottegeLivingAreaIcon,
                          }}
                        ></div>
                        <span>{cottage.cottegeLivingArea}</span>
                        <b>{value.cottegeParamsGroup.livingArea}</b>
                      </div>
                    </div>
                    <Link
                      to={value.cottegeAdvParamsGroup.buttonMoreLink}
                      className="button lined"
                    >
                      {value.cottegeAdvParamsGroup.buttonMoreText} <Arrow />
                    </Link>
                  </div>
                  <div className="cottage__image">
                    <GatsbyImage
                      image={
                        value.cottegeAdvParamsGroup.img.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={value.cottegeAdvParamsGroup.img.altText || ``}
                    />
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="cottages">
              {cottage.cottegesListClassic.map((value, i) => (
                <div key={i} className="cottage__item">
                  <div>
                    <h2>{value.name}</h2>
                    <div className="cottage__details">
                      <div>
                        <div
                          className="ico"
                          dangerouslySetInnerHTML={{
                            __html: cottage.cottegeTotalAreaIcon,
                          }}
                        ></div>
                        <span>{cottage.cottegeTotalArea}</span>
                        <b>{value.cottegeParamsGroup.totalArea}</b>
                      </div>
                      <div>
                        <div
                          className="ico"
                          dangerouslySetInnerHTML={{
                            __html: cottage.cottegeLevelsIcon,
                          }}
                        ></div>
                        <span>{cottage.cottegeLevels}</span>
                        <b>{value.cottegeParamsGroup.floorLevels}</b>
                      </div>
                      <div>
                        <div
                          className="ico"
                          dangerouslySetInnerHTML={{
                            __html: cottage.cottegeTotalCarsIcon,
                          }}
                        ></div>
                        <span>{cottage.cottegeTotalCars}</span>
                        <b>{value.cottegeParamsGroup.cars}</b>
                      </div>
                      <div>
                        <div
                          className="ico"
                          dangerouslySetInnerHTML={{
                            __html: cottage.cottegeLivingAreaIcon,
                          }}
                        ></div>
                        <span>{cottage.cottegeLivingArea}</span>
                        <b>{value.cottegeParamsGroup.livingArea}</b>
                      </div>
                    </div>
                    <Link
                      to={value.cottegeAdvParamsGroup.buttonMoreLink}
                      className="button lined"
                    >
                      {value.cottegeAdvParamsGroup.buttonMoreText} <Arrow />
                    </Link>
                  </div>
                  <div className="cottage__image">
                    <GatsbyImage
                      image={
                        value.cottegeAdvParamsGroup.img.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={value.cottegeAdvParamsGroup.img.altText || ``}
                    />
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
        </Tabs>
        <div className="alignCenter">
          <ReviewPopup
            buttonText={cottage.sectionCotteggesButtonViewing.text}
            forms={forms}
          />
          <Link
            to={cottage.sectionCotteggesButtonProjects.link}
            className="button minWidth orange"
          >
            {cottage.sectionCotteggesButtonProjects.text}
          </Link>
        </div>
        <div className="news__block">
          <div className="news">
            {postItems.map(({ node }, i) => (
              <Link key={i} to={node.uri} className="news__item">
                {node.articlesContent?.articleImg?.localFile?.childImageSharp
                  ?.gatsbyImageData && (
                  <div className="news__image">
                    <GatsbyImage
                      image={
                        node.articlesContent?.articleImg?.localFile
                          ?.childImageSharp?.gatsbyImageData
                      }
                      alt={node.articlesContent?.articleImg?.altText || ``}
                    />
                  </div>
                )}
                <span className="date">{node.date}</span>
                <strong className="news__title">{node.title}</strong>
                {!!node.articlesContent.articleExcerpt && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: node.articlesContent.articleExcerpt,
                    }}
                  ></div>
                )}
                <NewsButton />
              </Link>
            ))}
          </div>
          <div className="see-all">
            <h2>{content.sectionNewsTitle}</h2>
            <Link to={content.btnMoreNewsLink} className="button lined">
              {content.btnMoreNewsText} <Arrow />
            </Link>
            <img src={PatternThird} alt="" />
          </div>
        </div>
        <Contacts socials={socials.nodes[0].socialNetworks} forms={forms} contacts={contacts} />
      </Layout>
    </div>
  );
}

export const query = graphql`
  {
    page: allWpPage(
      filter: { slug: { eq: "main" }, locale: { locale: { eq: "uk" } } }
    ) {
      nodes {
        pageHome {
          btnMoreNewsLink
          btnMoreNewsText
          mainBannerTitle
          sectionInfrastructureButtonMoreLink
          sectionInfrastructureButtonMoreText
          sectionInfrastructureTitle
          sectionNewsTitle
          sectionRoutesButtonLink
          sectionRoutesButtonText
          sectionServiceTitle
          mainBannerImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
          sectionAboutAdvantages {
            icon
            text
          }
          sectionAboutContent {
            buttonLink
            buttonText
            description
            title
          }
          sectionGallery {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
          sectionInfrastructureObjects {
            icon
            name
          }
          sectionRoutesGallery {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
          sectionRoutesMap {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
          sectionRoutesTimeline {
            icon
            text
          }
          sectionServiceItemList {
            icon
            name
          }
        }
        uri
        slug
        locale {
          locale
        }
        seo {
          title
          metaDesc
        }
      }
    }
    infrastucture: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "infrastruktura-na-terytoriyi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        blockInfrastuctureObjects {
          infrasructureObjectsDesc
          infrasructureObjectsTitle
          sectionInfrastructureButtonMoreLink
          sectionInfrastructureButtonMoreText
          infrasructureObjects {
            description
            icon
            name
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          infrasructureObjectsImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
    cottages: allWpRepeatableBlocks(
      filter: { slug: { eq: "cotteges" }, locale: { locale: { eq: "uk" } } }
    ) {
      nodes {
        blockCotteges {
          classicToggleText
          cottegeLevels
          cottegeLevelsIcon
          cottegeLivingArea
          cottegeLivingAreaIcon
          cottegeTotalArea
          cottegeTotalAreaIcon
          cottegeTotalCars
          cottegeTotalCarsIcon
          modernToggleText
          textBeforeToggler
          sectionCotteggesButtonProjects {
            link
            text
          }
          sectionCotteggesButtonViewing {
            text
          }
          cottegesList {
            name
            cottegeAdvParamsGroup {
              buttonMoreLink
              buttonMoreText
              img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
            cottegeParamsGroup {
              cars
              floorLevels
              livingArea
              totalArea
            }
          }
          cottegesListClassic {
            name
            cottegeParamsGroup {
              cars
              floorLevels
              livingArea
              totalArea
            }
            cottegeAdvParamsGroup {
              buttonMoreLink
              buttonMoreText
              img {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
          }
        }
        slug
        uri
        locale {
          locale
        }
      }
    }
    posts: allWpPost(
      filter: {locale: {locale: {eq: "uk"}}}
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          slug
          uri
          title
          date(formatString: "DD.MM.YYYY")
          articlesContent {
            articleContent
            articleExcerpt
            articleImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          locale {
            locale
          }
        }
      }
    }
    life: allWpRepeatableBlocks(
      filter: {
        slug: { eq: "blok-perevagy-zhyttya" }
        locale: { locale: { eq: "uk" } }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        blockLifeAdvantages {
          advantagesMap {
            buttonLink
            buttonText
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          advantagesSlider {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
          advantagesTimeline {
            icon
            text
          }
          advantagesSliderNext
          advantagesSliderPrev
        }
      }
    }
    gmap: allWpRepeatableBlocks(
      filter: { slug: { eq: "google-karta" }, locale: { locale: { eq: "uk" } } }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        blockGoogleMap {
          mapCoords {
            lat
            lng
          }
        }
      }
    }
    contacts: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "kontaktna-informacziya" }
      }
    ) {
      nodes {
        slug
        title
        blockContacts {
          contactAddressText
          contactAddressTitle
          contactEmailAdressText
          contactEmailAdressTitle
          contactPhonesListTitle
          fieldGroupName
          scheduleListTitle
          contactPhonesList {
            phoneNumber
          }
          scheduleList {
            item
          }
        }
      }
    }
    forms: allWpRepeatableBlocks(
      filter: { locale: { locale: { eq: "uk" } }, slug: { eq: "formy" } }
    ) {
      nodes {
        formSettings {
          contactFormCommentPlaceholder
          contactFormEmailPlaceholder
          contactFormSendButton
          contactFormTitle
          invalidMailMsg
          invalidPhoneMsg
          modalCallbackButtonText
          modalCallbackTitle
          modalThanksButtonText
          modalThanksText
          modalThanksTitle
          modalViewingButtonText
          modalViewingFormTitle
          viewingFormDatetime
          viewingFormSendButton
          viewingFormTitle
          viewingFormUsername
        }
      }
    }
    footer: allWpRepeatableBlocks(filter: { slug: { eq: "footer" } }) {
      nodes {
        mainFooter {
          copyrightText
          footerLogo
          orderButtonIcon
          orderButtonText
          centrColumnItems {
            item
            name
          }
          leftColumnItems {
            name
            item
          }
          rightColumnItems {
            item
            name
          }
        }
        slug
        title
        locale {
          locale
        }
      }
    }
    header: allWpRepeatableBlocks(filter: { slug: { eq: "header" } }) {
      nodes {
        slug
        title
        locale {
          locale
        }
        mainHeader {
          headerLogo
          headerSubmenu {
            dropdownBtnIcon
            dropdownPages {
              pageTitle
              pageLink
            }
          }
          menuCallbackBtn {
            icon
            text
          }
          menuOrderHouseBtn {
            icon
            link
            text
          }
          headerMenuFix {
            pageLink
            pageTitle
          }
          headerMessengerMenu {
            messengerList {
              icon
              link
            }
            dropdownBtnIcon
          }
        }
      }
    }
    socials: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "soczialni-merezhi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        socialNetworks {
          socialNetworks {
            icon
            link
            title
          }
        }
      }
    }
    copyright: allWpRepeatableBlocks(
      filter: {slug: {eq: "copyrights"}}
    ) {
      nodes {
        slug
        title
        blockCopyrights {
          copyrightTextAfter
          copyrightTextBefore
          copyrightTextMiddle
          userAgreement {
            linkText
          }
          cookiesPolicy {
            linkText
          }
        }
        uri
        locale {
          locale
        }
      }
    }
    user: allWpRepeatableBlocks(filter: {slug: {eq: "ugoda-korystuvacha"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    cookies: allWpRepeatableBlocks(filter: {slug: {eq: "polityka-cookies"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
  }
`;
