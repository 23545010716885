import * as React from "react";
import { Link } from "gatsby";
import GalleryButton from "../components/gallery_button";
import { GatsbyImage } from "gatsby-plugin-image";

import "./gid.sass";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// Patterns
import PatternFirst from "../images/pattern_01.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

export default function Gid({ content }) {
  return (
    <div className="gidBox">
      <div className="pattern">
        <img src={PatternFirst} alt="Pattern" />
      </div>
      <ul className="roadmap">
        {content.advantagesTimeline.map(({ node }, i) => (
          <li key={i} className="roadmap-item">
            <div className="roadmap-icon" dangerouslySetInnerHTML={{ __html: content.advantagesTimeline[i].icon }}></div>
            <div className="roadmap-desc">
              {content.advantagesTimeline[i].text}
            </div>
          </li>
        ))}
      </ul>
      <div className="gidBox-content">
        <div className="gidBox-map">
          <div className="gidBox-middle">
            <GatsbyImage
              image={content.advantagesMap.img.localFile.childImageSharp.gatsbyImageData}
              alt={content.advantagesMap.img.altText || ``}
            />
            {/* <Link
              className="button"
              to={content.advantagesMap.buttonLink}
            >
              {content.advantagesMap.buttonText}
            </Link> */}
          </div>
        </div>
        <div id="seacondSwiper" className="mainSwiper smallStyle">
          <GalleryButton text={content.advantagesSliderNext} />
          <Swiper
            navigation={{
              nextEl: "#seacondSwiper .swiperButton",
            }}
            loop={true}
            speed={800}
            spaceBetween={16}
            slidesPerView={"auto"}
            breakpoints={{
              "1300": {
                "spaceBetween": 36,
              },
              "768": {
                "spaceBetween": 24,
              }
            }}
          >
            {content.advantagesSlider.map((value, i) => (
              <SwiperSlide key={i}>
                <GatsbyImage
                  image={value.localFile.childImageSharp.gatsbyImageData}
                  alt={value.altText || ``}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
