import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import { useDetectOutsideClick } from "../components/useDetectOutsideClick";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

import Close from "../images/icons/close.svg";

const schema = yup.object().shape({
  reviewPopupPhone: yup.string().required(),
});

const defaultValues = {
  reviewPopupName: "",
  reviewPopupPhone: "+38 (0--) --- -- --",
  reviewPopupDate: ""
};

export default function Review({  buttonIcon, buttonText, forms }) {
  const form = forms.formSettings;

  const popupRef = useRef();
  const [isActive, setIsActive] = useDetectOutsideClick(popupRef, false);
  const [isMsg, setIsMsg] = useState(false);
  const onClick = () => setIsActive(!isActive);
  const onMsg = () => setIsMsg(!isMsg);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });
  const onSubmit = (data, e) => {
    fetch(
      "https://backend.roslavychi.ua/wp-json/roslavichi/v1/mail/?name=" +
        data.reviewPopupName +
        "&tel=" +
        data.reviewPopupPhone +
        "&time=" +
        data.reviewPopupDate +
        "&form=" +
        data.reviewPopupTitle +
        ""
    )
      .then((response) => response.json())
      .then((data) => {
        reset()
        setIsMsg(!isMsg);
        setIsActive(!isActive);
        trackCustomEvent({
          category: "form",
          action: "submit",
          label: "view_enroll"
        })
      });
  };

  return (
    <>
      <div
        ref={popupRef}
        className={`modalWrapper ${isActive ? "display" : ""} ${isMsg ? "afterSend display" : ""}`}
      >
        <form
          id="review"
          className="modalContent"
          onSubmit={handleSubmit(onSubmit)}
        >
          <span className="modalClose" onClick={onClick}>
            <Close />
          </span>
          <h2>{form.modalViewingFormTitle}</h2>
          <div className="input">
            <input
              type="text"
              placeholder={form.viewingFormUsername}
              {...register("reviewPopupName", { required: true })}
            />
          </div>
          <input
            type="hidden"
            value={form.modalViewingFormTitle}
            {...register("reviewPopupTitle", { required: true })}
          />
          <div className="input">
            {errors.reviewPopupPhone && errors.reviewPopupPhone.type === "required" && (
              <span className="errorMsg">{form.invalidPhoneMsg}</span>
            )}
            <Controller
              control={control}
              name="reviewPopupPhone"
              render={({ field: { onChange, name, value } }) => (
                <NumberFormat
                  type="tel"
                  placeholder="+38 (0--) --- -- --"
                  format="+38 (0##) ### ## ##"
                  name={name}
                  value={value}
                  mask="-"
                  allowEmptyFormatting
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="input">
            <span className="errorMsg">{errors.reviewPopupDate?.message}</span>
            <Controller
              control={control}
              name="reviewPopupDate"
              render={({ field: { onChange, name, value } }) => (
                <NumberFormat
                  type="text"
                  placeholder={form.viewingFormDatetime}
                  format="##.## / ##:00"
                  name={name}
                  value={value}
                  mask={["Д", "Д", "М", "М", "-", "-"]}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="buttonBox">
            <button className="button lighter" type="submit">
              {form.modalViewingButtonText}
            </button>
          </div>
        </form>
        <div className="modalContent msgBox">
          <span className="modalClose" onClick={onMsg}>
            <Close />
          </span>
          <h2>{form.modalThanksTitle}</h2>
          <p>{form.modalThanksText}</p>
          <div className="alignCenter">
            <button onClick={onMsg} className="button lighter">
              {form.modalThanksButtonText}
            </button>
          </div>
        </div>
      </div>
      <button className={`button ${buttonIcon ? "iconButton" : "minWidth"}`} onClick={onClick}>
        {!!buttonIcon && (
          <div
            className="button-icon"
            dangerouslySetInnerHTML={{
              __html: buttonIcon,
            }}
          ></div>
        )}
        {buttonText}
      </button>
    </>
  );
}
