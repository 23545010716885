import * as React from "react";

import './news_button.sass';

// Icons
import ArrowForward from "../images/icons/arrow_forward.svg";

export default function Gid() {
  return (
    <button type="button" className="newsButton"><ArrowForward /></button>
  );
}
