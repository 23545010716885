import React, { useRef } from "react";
import { useDetectOutsideClick } from "../components/useDetectOutsideClick";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Contacts({ value, index }) {
  const dropdownRef = useRef();
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);
  
  return (
    <span
      key={index}
      className={`structure__item ${isActive ? "open" : ""}`}
      ref={dropdownRef}
      onClick={onClick}
    >
      <div className="structure__ico" dangerouslySetInnerHTML={{ __html: value.icon }}></div>
      <div className="structure__content">
        <GatsbyImage
          image={value.img.localFile.childImageSharp.gatsbyImageData}
          alt={value.img.altText || ``}
        />
        <span>{value.name}</span>
      </div>
    </span>
  );
}
