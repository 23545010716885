import React, { useRef, Component } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import { useDetectOutsideClick } from "../components/useDetectOutsideClick";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

// Icons
import Close from "../images/icons/close.svg";

const schema = yup.object().shape({
  phone: yup.string().required(),
  email: yup.string().email().required(),
});

const defaultValues = {
  formMessage: "",
  phone: "",
  email: "",
};

export default function Contacts({ contacts, socials, forms }) {
  const content = contacts.blockContacts;
  const form = forms.formSettings;

  const popupRef = useRef();
  const [isActive, setIsActive] = useDetectOutsideClick(popupRef, false);
  const onClick = () => setIsActive(!isActive);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const onSubmit = (data, e) => {
    fetch(
      "https://backend.roslavychi.ua/wp-json/roslavichi/v1/mail/?tel=" +
        data.phone +
        "&text=" +
        data.formMessage +
        "&email=" +
        data.email +
        "&form=" +
        data.formTitle +
        ""
    )
      .then((response) => response.json())
      .then((data) => {
        reset();
        setIsActive(!isActive);
        trackCustomEvent({
          category: "form",
          action: "submit",
          label: "contact_enroll"
        })
      });
  };

  return (
    <>
      <div className="recording contacts">
        <div className="recording-content">
          <div>
            <h2>{form.contactFormTitle}</h2>
            <dl>
              <dt>{content.scheduleListTitle}</dt>
              <dd>
                {content.scheduleList.map(({ item }, i) => (
                  <div key={i}>{item}</div>
                ))}
              </dd>
              <dt>{content.contactAddressTitle}</dt>
              <dd dangerouslySetInnerHTML={{ __html: content.contactAddressText }}></dd>
              <dt>{content.contactPhonesListTitle}</dt>
              <dd>
                {content.contactPhonesList.map(({ phoneNumber }, i) => (
                  <div key={i}>{phoneNumber}</div>
                ))}
              </dd>
              <dt>{content.contactEmailAdressTitle}</dt>
              <dd dangerouslySetInnerHTML={{ __html: content.contactEmailAdressText }}></dd>
            </dl>
            <div className="social">
              {socials.socialNetworks.map((value, i) => (
                <a
                  key={i}
                  rel="noreferrer"
                  target="_blank"
                  href={value.link}
                  aria-label={value.title}
                  dangerouslySetInnerHTML={{ __html: value.icon }}
                ></a>
              ))}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="hidden"
              value={form.contactFormTitle}
              {...register("formTitle", { required: true })}
            />
            <div className="input">
              {errors.phone && errors.phone.type === "required" && (
                <span className="errorMsg">{form.invalidPhoneMsg}</span>
              )}
              <Controller
                control={control}
                {...register("phone", {
                  required: "required",
                  pattern: {
                    value: /^\+[0-9]{2}\s\((\d+)\)\s\d{3}\s\d{2}\s\d{2}/,
                  },
                })}
                render={({ field: { onChange, name, value } }) => (
                  <NumberFormat
                    type="tel"
                    placeholder="+38 (0--) --- -- --"
                    format="+38 (0##) ### ## ##"
                    name={name}
                    value={value}
                    mask="-"
                    allowEmptyFormatting
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="input">
              {errors.email && (
                <span className="errorMsg">{form.invalidMailMsg}</span>
              )}
              <input
                {...register("email", {
                  required: "required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                  },
                })}
                placeholder={form.contactFormEmailPlaceholder}
                type="email"
              />
            </div>
            <div className="input">
              <textarea
                {...register("formMessage")}
                cols="30"
                rows="10"
                placeholder="Коментар"
              ></textarea>
            </div>
            <div className="buttonBox">
              <button className="button lighter" type="submit">
                {form.contactFormSendButton}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="contacts__map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1275.9342405229238!2d30.458630466199583!3d50.23836188945447!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x58adacab8a094c28!2z0JrQvtGC0YLQtdC00LbQvdGL0Lkg0LPQvtGA0L7QtNC-0LogItCg0L7RgdC70LDQstC40YfQuCI!5e0!3m2!1sru!2sua!4v1640281340804!5m2!1sru!2sua"
          width="100%"
          height="100%"
          title="Google Map"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div
        ref={popupRef}
        className={`modalWrapper afterSend ${isActive ? "display" : ""}`}
      >
        <div className="modalContent msgBox display">
          <span className="modalClose" onClick={onClick}>
            <Close />
          </span>
          <h2>{form.modalThanksTitle}</h2>
          <p>{form.modalThanksText}</p>
          <div className="alignCenter">
            <button onClick={onClick} className="button lighter">
              {form.modalThanksButtonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
