import * as React from "react";

import './gallery_button.sass';

// Icons
import ArrowForward from "../images/icons/arrow_forward.svg";

export default function Gid({ text }) {
  return (
    <button className="swiperButton">{text}<ArrowForward /></button>
  );
}
